$(document).ready(function onDocumentReady(e) {

    var $models = $('.js-model');

    var showModelInfo = function($model) {

        var $content = $(''
            + '<div class="image"><img src="' + $model.data('image')+ '" alt="' + $model.data('model') + '" class="img-thumbnail"></div>'
            + '<div class="model">' + $model.data('model') + '</div>'
            + '<div class="dimensions">' + $model.data('width') + ' x ' + $model.data('height') + ' x ' + $model.data('depth') + 'mm</div>'
            + '<div class="select">'
            + ' <button type="button" class="js-select-model btn btn-success" data-type="' + $model.data('type') + '" data-max_w="' + $model.data('max_w') + '" data-max_h="' + $model.data('max_h') + '" data-id="' + $model.data('id') + '">Select</button>'
            + '</div>');

        $('.js-model-preview').html($content).css({position: 'fixed'});
    };

    $models.on('click', function onModelClick(e) {

        showModelInfo($(this));
    });

    $(document).on('click', '.js-select-model', function onSelectModelClick(e) {

        $('.js-product_id').val($(this).data('id'));
        $('#model-select-form').submit();
    });
});