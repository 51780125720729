$(document).ready(function onDocumentReady(e) {
    $(document).on('click', '.js-add-attachment', function onAddAttachmentClick(e) {
        var html = ''
        + '<div class="js-attachment">'
        + '<div class="col-sm-5 col-sm-offset-3">'
        + '<input style="margin-top: 15px;" class="form-control" multiple="1" name="attachments[]" type="file">'
        + '</div>'
        + '<div class="col-sm-1 js-buttons">'
        + '<button style="margin-top: 15px;" type="button" class="btn primary js-remove-attachment">'
        + '<i class="glyphicon glyphicon-minus"></i>'
        + '</button>'
        + '</div>'
        + '</div>';
        $('.js-attachments').append(html);
    });

    $(document).on('click', '.js-remove-attachment', function onRemoveAttachmentClick(e) {
        $(this).parents('.js-attachment').remove();
    });
});