$(document).ready(function onDocumentReady() {

    var saveImage = function($canvas) {

        var base64 = $canvas.getCanvasImage('png');

        $.ajax({
            url: '/images/save/' + $canvas.data('proposal-id') + '/' + $canvas.data('bank-id'),
            type: 'post',
            data: {
                image: base64
            },
            success: function (data) {}
        });
    };

    var dimensionsSuffix  = 'mm';
    var textMargin = 10;
    var fontSize = 14;

    var drawHorizontalDimension = function ($canvas, data) {

        //draw line with arrows
        $canvas.drawLine({
            layer: true,
            draggable: true,
            groups: ['shapes'],
            dragGroups: ['shapes'],
            strokeStyle: '#000',
            strokeWidth: 1,
            rounded: true,
            startArrow: true,
            endArrow: true,
            arrowRadius: 10,
            arrowAngle: 20,
            x1: data.topLeft.x + 1,
            y1: data.topLeft.y,

            x2: data.topRight.x - 1,
            y2: data.topRight.y
        });
        //draw left line
        $canvas.drawLine({
            layer: true,
            draggable: true,
            groups: ['shapes'],
            dragGroups: ['shapes'],
            strokeStyle: '#000',
            strokeWidth: 1,
            rounded: true,
            x1: data.topLeft.x,
            y1: data.topLeft.y,

            x2: data.bottomLeft.x,
            y2: data.bottomLeft.y
        });
        //draw right line
        $canvas.drawLine({
            layer: true,
            draggable: true,
            groups: ['shapes'],
            dragGroups: ['shapes'],
            strokeStyle: '#000',
            strokeWidth: 1,
            rounded: true,
            x1: data.topRight.x,
            y1: data.topRight.y,

            x2: data.bottomRight.x,
            y2: data.bottomRight.y
        });

        //draw dimension text
        $canvas.drawText({
            layer: true,
            draggable: true,
            groups: ['shapes'],
            dragGroups: ['shapes'],
            fillStyle: '#000',
            x: (data.topLeft.x + data.topRight.x) / 2,
            y: data.topLeft.y - textMargin,
            fontSize: fontSize,
            fontFamily: 'Arial, sans-serif',
            text: data.value + dimensionsSuffix,
            rotate: 0,
            fromCenter: true
        });
    };

    var drawVerticalDimension = function ($canvas, data) {

        //draw dimension line
        $canvas.drawLine({
            layer: true,
            draggable: true,
            groups: ['shapes'],
            dragGroups: ['shapes'],
            strokeStyle: '#000',
            strokeWidth: 1,
            rounded: true,
            startArrow: true,
            endArrow: true,
            arrowRadius: 10,
            arrowAngle: 20,
            x1: data.topRight.x,
            y1: data.topRight.y + 1,

            x2: data.bottomRight.x,
            y2: data.bottomRight.y - 1
        });
        //draw top line
        $canvas.drawLine({
            layer: true,
            draggable: true,
            groups: ['shapes'],
            dragGroups: ['shapes'],
            strokeStyle: '#000',
            strokeWidth: 1,
            rounded: true,
            x1: data.topRight.x,
            y1: data.topRight.y,

            x2: data.topLeft.x,
            y2: data.topLeft.y
        });
        //draw bottom line
        $canvas.drawLine({
            layer: true,
            draggable: true,
            groups: ['shapes'],
            dragGroups: ['shapes'],
            strokeStyle: '#000',
            strokeWidth: 1,
            rounded: true,
            x1: data.bottomRight.x,
            y1: data.bottomRight.y,

            x2: data.bottomLeft.x,
            y2: data.bottomLeft.y
        });
        //draw dimension text
        $canvas.drawText({
            layer: true,
            draggable: true,
            groups: ['shapes'],
            dragGroups: ['shapes'],
            fillStyle: '#000',
            x: data.topRight.x - textMargin,
            y: (data.topRight.y + data.bottomRight.y) / 2,
            fontSize: fontSize,
            fontFamily: 'Arial, sans-serif',
            text: data.value + dimensionsSuffix,
            rotate: -90,
            fromCenter: true
        });
    };

    var drawImage = function(config) {

        var image = new Image();

        image.onload = function() {

            var mainRectX = 50.5;
            var mainRectY = 100.5;

            var mainRectWidth = this.width - 1;
            var mainRectHeight = this.height - 1;

            var dotedLineWidth = 30;
            var dotedLineHeight = 30;

            var border = 0;
            if (config.additionalSize > 0) {
                border = Math.round(((this.width * (config.mainWidth + config.additionalSize )/ config.mainWidth) - this.width) / 2);
            }

            var lineWidth = 3;

            var pedestalLegsHeight = Math.round((mainRectWidth * (config.fullHeight - config.mainHeight - config.additionalSize)) / config.mainWidth);

            var sideLegsHeight = pedestalLegsHeight + mainRectHeight + border;

            var sideDimensionOffset = config.sideLegs ? 10 : 0;

            var lines = 2;
            if (config.sideLegs || parseInt(config.pedestalLegs) > 0) {
                lines = 3;
            }

            var sideViewOffset = 125;
            if (lines > 2) {
                sideViewOffset = 150;
            }

            var legsBottom = 30;

            var sideRectX = mainRectX + mainRectWidth + sideViewOffset + sideDimensionOffset;
            var sideRectY = mainRectY;

            var sideRectWidth = Math.round((mainRectWidth * config.mainDepth) / config.mainWidth) - 1;
            var sideRectHeight = this.height - 1;

            var $canvas = $('#bank-canvas-' + config.bankId);

            var calculatedHeight = Math.round(pedestalLegsHeight + mainRectHeight + 150);
            var minHeight = (calculatedHeight < 350) ? 350 : calculatedHeight;

            $canvas.attr('height', minHeight);

            $canvas.on('click', function onCanvasClick(e) {
                saveImage($canvas);
            });
            $canvas.on('touchend', function onCanvasTouchEnd(e) {
                saveImage($canvas);
            });

            //draw main rectangle
            $canvas.drawRect({
                layer: true,
                draggable: true,
                groups: ['shapes'],
                dragGroups: ['shapes'],
                strokeStyle: '#000',
                strokeWidth: lineWidth,
                x: mainRectX,
                y: mainRectY,
                width: mainRectWidth,
                height: mainRectHeight,
                fromCenter: false
            });

            if (config.additionalSize) {

                //draw additional rectangle
                $canvas.drawRect({
                    layer: true,
                    draggable: true,
                    groups: ['shapes'],
                    dragGroups: ['shapes'],
                    strokeStyle: '#000',
                    strokeWidth: lineWidth,
                    x: mainRectX - border,
                    y: mainRectY - border,
                    width: mainRectWidth + (border * 2),
                    height: mainRectHeight + (border * 2),
                    fromCenter: false
                });

                if (config.additionalSizeType == 3) {
                    //draw 45 degrees lines

                    //top left
                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        x1: mainRectX,
                        y1: mainRectY,

                        x2: mainRectX - border,
                        y2: mainRectY - border
                    });

                    //top right
                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        x1: mainRectX + mainRectWidth,
                        y1: mainRectY,

                        x2: mainRectX + mainRectWidth + border,
                        y2: mainRectY - border
                    });

                    //bottom right
                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        x1: mainRectX + mainRectWidth,
                        y1: mainRectY + mainRectHeight,

                        x2: mainRectX + mainRectWidth + border,
                        y2: mainRectY + mainRectHeight + border
                    });

                    //bottom left
                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        x1: mainRectX,
                        y1: mainRectY + mainRectHeight,

                        x2: mainRectX - border,
                        y2: mainRectY + mainRectHeight + border
                    });
                }
            }

            //main dimensions
            drawVerticalDimension($canvas, {
                topRight: {
                    x: mainRectX + border + mainRectWidth + dotedLineWidth + sideDimensionOffset,
                    y: mainRectY - 1
                },
                bottomRight: {
                    x: mainRectX + border + mainRectWidth + dotedLineWidth + sideDimensionOffset,
                    y: mainRectY + mainRectHeight + 1
                },
                topLeft: {
                    x: mainRectX + border + mainRectWidth + sideDimensionOffset,
                    y: mainRectY - 1
                },
                bottomLeft: {
                    x: mainRectX + border + mainRectWidth + sideDimensionOffset,
                    y: mainRectY + mainRectHeight + 1
                },
                value: config.mainHeight
            });

            drawHorizontalDimension($canvas, {
                topRight: {
                    x: mainRectX + mainRectWidth + 1,
                    y: mainRectY - border - dotedLineHeight
                },
                bottomRight: {
                    x: mainRectX + mainRectWidth + 1,
                    y: mainRectY - border
                },
                topLeft: {
                    x: mainRectX - 1,
                    y: mainRectY - border - dotedLineHeight
                },
                bottomLeft: {
                    x: mainRectX - 1,
                    y: mainRectY - border
                },
                value: config.mainWidth
            });

            if (config.additionalSize) {

                //additional dimensions
                drawVerticalDimension($canvas, {
                    topRight: {
                        x: mainRectX + border + mainRectWidth + dotedLineWidth + dotedLineWidth + sideDimensionOffset,
                        y: mainRectY - border - 1
                    },
                    bottomRight: {
                        x: mainRectX + border + mainRectWidth + dotedLineWidth + dotedLineWidth + sideDimensionOffset,
                        y: mainRectY + mainRectHeight + border + 1
                    },
                    topLeft: {
                        x: mainRectX + border + mainRectWidth + sideDimensionOffset,
                        y: mainRectY - border - 1
                    },
                    bottomLeft: {
                        x: mainRectX + border + mainRectWidth + sideDimensionOffset,
                        y: mainRectY + mainRectHeight + border + 1
                    },
                    value: config.mainHeight + config.additionalSize
                });

                drawHorizontalDimension($canvas, {
                    topRight: {
                        x: mainRectX + mainRectWidth + border + 1,
                        y: mainRectY - border - (dotedLineHeight * 2)
                    },
                    bottomRight: {
                        x: mainRectX + mainRectWidth + border + 1,
                        y: mainRectY - border
                    },
                    topLeft: {
                        x: mainRectX - border - 1,
                        y: mainRectY - border - (dotedLineHeight * 2)
                    },
                    bottomLeft: {
                        x: mainRectX - border - 1,
                        y: mainRectY - border
                    },
                    value: config.mainWidth + config.additionalSize
                });
            }

            //draw side main rectangle
            $canvas.drawRect({
                layer: true,
                draggable: true,
                groups: ['shapes'],
                dragGroups: ['shapes'],
                strokeStyle: '#000',
                strokeWidth: lineWidth,
                x: sideRectX,
                y: sideRectY,
                width: sideRectWidth,
                height: sideRectHeight,
                fromCenter: false
            });

            drawVerticalDimension($canvas, {
                topRight: {
                    x: sideRectX + sideRectWidth + dotedLineWidth,
                    y: sideRectY - 1
                },
                bottomRight: {
                    x: sideRectX + sideRectWidth + dotedLineWidth,
                    y: sideRectY + sideRectHeight + 1
                },
                topLeft: {
                    x: sideRectX + sideRectWidth,
                    y: sideRectY - 1
                },
                bottomLeft: {
                    x: sideRectX + sideRectWidth,
                    y: sideRectY + sideRectHeight + 1
                },
                value: config.mainHeight
            });

            if (config.additionalSize) {

                drawVerticalDimension($canvas, {
                    topRight: {
                        x: sideRectX + sideRectWidth + (dotedLineWidth * 2),
                        y: sideRectY - border - 1
                    },
                    bottomRight: {
                        x: sideRectX + sideRectWidth + (dotedLineWidth * 2),
                        y: sideRectY + sideRectHeight + border + 1
                    },
                    topLeft: {
                        x: sideRectX + sideRectWidth,
                        y: sideRectY - border - 1
                    },
                    bottomLeft: {
                        x: sideRectX + sideRectWidth,
                        y: sideRectY + sideRectHeight + border + 1
                    },
                    value: config.mainHeight + config.additionalSize
                });
            }

            if (config.additionalSize) {

                var additionalDepth = 0;

                if (parseInt(config.additionalSizeType) === 1) {
                    additionalDepth = 10;

                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        x1: sideRectX - border + sideRectWidth + 4.5,
                        y1: sideRectY - border,

                        x2: sideRectX - border,
                        y2: sideRectY - border,

                        x3: sideRectX - border,
                        y3: sideRectY + sideRectHeight + border,

                        x4: sideRectX + sideRectWidth + 0.5,
                        y4: sideRectY + sideRectHeight + border
                    });

                    //draw dimensions
                    drawHorizontalDimension($canvas, {
                        topRight: {
                            x: sideRectX + sideRectWidth + 3,
                            y: sideRectY - border - (dotedLineHeight * 2)
                        },
                        bottomRight: {
                            x: sideRectX + sideRectWidth + 3,
                            y: sideRectY - border
                        },
                        topLeft: {
                            x: sideRectX - 2,
                            y: sideRectY - border - (dotedLineHeight * 2)
                        },
                        bottomLeft: {
                            x: sideRectX - 2,
                            y: sideRectY
                        },
                        value: parseInt(config.mainDepth) + additionalDepth
                    });

                    drawHorizontalDimension($canvas, {
                        topRight: {
                            x: sideRectX + sideRectWidth + 1,
                            y: sideRectY - border - dotedLineHeight
                        },
                        bottomRight: {
                            x: sideRectX + sideRectWidth + 1,
                            y: sideRectY - border
                        },
                        topLeft: {
                            x: sideRectX - 2,
                            y: sideRectY - border - dotedLineHeight
                        },
                        bottomLeft: {
                            x: sideRectX - 2,
                            y: sideRectY
                        },
                        value: parseInt(config.mainDepth)
                    });
                }

                if (parseInt(config.additionalSizeType) === 2) {
                    additionalDepth = 20;

                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        closed: true,
                        x1: sideRectX,
                        y1: sideRectY,

                        x2: sideRectX,
                        y2: sideRectY - border,

                        x3: sideRectX + sideRectWidth + border,
                        y3: sideRectY - border,

                        x4: sideRectX + sideRectWidth + border,
                        y4: sideRectY
                    });

                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        closed: true,
                        x1: sideRectX,
                        y1: sideRectY + sideRectHeight,

                        x2: sideRectX,
                        y2: sideRectY + sideRectHeight + border,

                        x3: sideRectX + sideRectWidth + border,
                        y3: sideRectY + sideRectHeight + border,

                        x4: sideRectX + sideRectWidth + border,
                        y4: sideRectY + sideRectHeight
                    });

                    drawHorizontalDimension($canvas, {
                        topRight: {
                            x: sideRectX + sideRectWidth + border + 1,
                            y: sideRectY - border - (dotedLineHeight * 2)
                        },
                        bottomRight: {
                            x: sideRectX + sideRectWidth + border + 1,
                            y: sideRectY - border
                        },
                        topLeft: {
                            x: sideRectX - 1,
                            y: sideRectY - border - (dotedLineHeight * 2)
                        },
                        bottomLeft: {
                            x: sideRectX - 1,
                            y: sideRectY
                        },
                        value: parseInt(config.mainDepth) + additionalDepth
                    });

                    drawHorizontalDimension($canvas, {
                        topRight: {
                            x: sideRectX + sideRectWidth + 1,
                            y: sideRectY - border - dotedLineHeight
                        },
                        bottomRight: {
                            x: sideRectX + sideRectWidth + 1,
                            y: sideRectY - border
                        },
                        topLeft: {
                            x: sideRectX - 1,
                            y: sideRectY - border - dotedLineHeight
                        },
                        bottomLeft: {
                            x: sideRectX - 1,
                            y: sideRectY
                        },
                        value: parseInt(config.mainDepth)
                    });
                }

                if (parseInt(config.additionalSizeType) === 3) {

                    var offset = 3;

                    //bottom _
                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        x1: sideRectX + sideRectWidth + 1.5,
                        y1: sideRectY + sideRectHeight + offset,
                        
                        x2: sideRectX + sideRectWidth - border + 1.5,
                        y2: sideRectY + sideRectHeight + offset
                    });

                    //top _
                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        x1: sideRectX + sideRectWidth + 1.5,
                        y1: sideRectY - offset,
                        
                        x2: sideRectX + sideRectWidth - border + 1.5,
                        y2: sideRectY - offset
                    });

                    //draw vertical line in front
                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        x1: sideRectX + sideRectWidth,
                        y1: sideRectY - border - 1.5,
                        
                        x2: sideRectX + sideRectWidth,
                        y2: sideRectY + sideRectHeight + border + 1.5
                    });

                    //draw dimensions
                    drawHorizontalDimension($canvas, {
                        topRight: {
                            x: sideRectX + sideRectWidth + 1,
                            y: sideRectY - border - dotedLineHeight
                        },
                        bottomRight: {
                            x: sideRectX + sideRectWidth + 1,
                            y: sideRectY - border
                        },
                        topLeft: {
                            x: sideRectX - 1,
                            y: sideRectY - border - dotedLineHeight
                        },
                        bottomLeft: {
                            x: sideRectX - 1,
                            y: sideRectY
                        },
                        value: parseInt(config.mainDepth)
                    });
                }
            } else {
                //draw dimensions
                drawHorizontalDimension($canvas, {
                    topRight: {
                        x: sideRectX + sideRectWidth + 1,
                        y: sideRectY - border - dotedLineHeight
                    },
                    bottomRight: {
                        x: sideRectX + sideRectWidth + 1,
                        y: sideRectY - border
                    },
                    topLeft: {
                        x: sideRectX - 1,
                        y: sideRectY - border - dotedLineHeight
                    },
                    bottomLeft: {
                        x: sideRectX - 1,
                        y: sideRectY
                    },
                    value: config.mainDepth
                });
            }

            //draw pedestal legs
            if (parseInt(config.pedestalLegs) > 0) {
                for (var i = 1; i < (parseInt(config.pedestalLegs) + 1); i++) {

                    var position = 0;
                    var w = 0;
                    if (config.sideLegs) {
                        w = Math.round(mainRectWidth / (parseInt(config.pedestalLegs) + 1));
                        position = w * i;
                    } else {
                        if (parseInt(config.w) === 5 && parseInt(config.pedestalLegs) === 2) {
                            position = (i === 1)
                                ? Math.round(mainRectWidth / parseInt(config.w))
                                : Math.round(mainRectWidth - (mainRectWidth / parseInt(config.w)));
                        } else {
                            w = Math.round(mainRectWidth / parseInt(config.pedestalLegs)) / 2;
                            position = (Math.round(mainRectWidth / parseInt(config.pedestalLegs)) * i) - w;
                        }
                    }

                    $canvas.drawRect({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        x: mainRectX + position - 5,
                        y: mainRectY + border + mainRectHeight,
                        width: 10,
                        height: pedestalLegsHeight,
                        fromCenter: false
                    });

                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        x1: mainRectX + position - (legsBottom / 2) + 0.5,
                        y1: mainRectY + border + mainRectHeight + pedestalLegsHeight,
                        
                        x2: mainRectX + position + (legsBottom / 2) + 0.5,
                        y2: mainRectY + border + mainRectHeight + pedestalLegsHeight
                    });
                }
            }

            if (config.sideLegs) {
                $canvas.drawRect({
                    layer: true,
                    draggable: true,
                    groups: ['shapes'],
                    dragGroups: ['shapes'],
                    strokeStyle: '#000',
                    strokeWidth: lineWidth,
                    x: mainRectX - border - 10,
                    y: mainRectY,
                    width: 10,
                    height: sideLegsHeight,
                    fromCenter: false
                });

                $canvas.drawLine({
                    layer: true,
                    draggable: true,
                    groups: ['shapes'],
                    dragGroups: ['shapes'],
                    strokeStyle: '#000',
                    strokeWidth: lineWidth,
                    x1: mainRectX - border - 5 - (legsBottom / 2) + 0.5,
                    y1: mainRectY + border + mainRectHeight + pedestalLegsHeight,
                    
                    x2: mainRectX - border - 5 + (legsBottom / 2) + 0.5,
                    y2: mainRectY + border + mainRectHeight + pedestalLegsHeight
                });

                $canvas.drawRect({
                    layer: true,
                    draggable: true,
                    groups: ['shapes'],
                    dragGroups: ['shapes'],
                    strokeStyle: '#000',
                    strokeWidth: lineWidth,
                    x: mainRectX + border + mainRectWidth,
                    y: mainRectY,
                    width: 10,
                    height: sideLegsHeight,
                    fromCenter: false
                });

                $canvas.drawLine({
                    layer: true,
                    draggable: true,
                    groups: ['shapes'],
                    dragGroups: ['shapes'],
                    strokeStyle: '#000',
                    strokeWidth: lineWidth,
                    x1: mainRectX + border + mainRectWidth + 5 - (legsBottom / 2) + 0.5,
                    y1: mainRectY + border + mainRectHeight + pedestalLegsHeight,

                    x2: mainRectX + border + mainRectWidth + 5 + (legsBottom / 2) + 0.5,
                    y2: mainRectY + border + mainRectHeight + pedestalLegsHeight
                });
            }

            if (config.sideLegs || parseInt(config.pedestalLegs) > 0) {

                var sidePosition = Math.round(sideRectWidth / 2);

                var legsOffset = 0;
                if (parseInt(config.additionalSizeType) === 2) {
                    legsOffset = border;
                }

                //draw leg on side view
                if (config.sideLegs) {
                    $canvas.drawRect({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        x: sideRectX + sidePosition - 5,
                        y: sideRectY,
                        width: 10,
                        height: sideRectHeight + pedestalLegsHeight + border,
                        fromCenter: false
                    });

                    //draw "hidden" lines
                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#fff',
                        strokeWidth: 2,
                        x1: sideRectX + sidePosition - 2.5,
                        y1: sideRectY + sideRectHeight - 40,

                        x2: sideRectX + sidePosition - 2.5,
                        y2: sideRectHeight + pedestalLegsHeight + border
                    });
                    $canvas.drawLine({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#fff',
                        strokeWidth: 2,
                        x1: sideRectX + sidePosition + 2.5,
                        y1: sideRectY + sideRectHeight - 40,

                        x2: sideRectX + sidePosition + 2.5,
                        y2: sideRectHeight + pedestalLegsHeight + border
                    });
                } else {
                    $canvas.drawRect({
                        layer: true,
                        draggable: true,
                        groups: ['shapes'],
                        dragGroups: ['shapes'],
                        strokeStyle: '#000',
                        strokeWidth: lineWidth,
                        x: sideRectX + sidePosition - 5,
                        y: sideRectY + sideRectHeight + legsOffset,
                        width: 10,
                        height: pedestalLegsHeight + border - legsOffset,
                        fromCenter: false
                    });
                }

                $canvas.drawLine({
                    layer: true,
                    draggable: true,
                    groups: ['shapes'],
                    dragGroups: ['shapes'],
                    strokeStyle: '#000',
                    strokeWidth: lineWidth,
                    x1: sideRectX + sidePosition - (legsBottom / 2) + 0.5,
                    y1: sideRectY + border + sideRectHeight + pedestalLegsHeight,

                    x2: sideRectX + sidePosition + (legsBottom / 2) + 0.5,
                    y2: sideRectY + border + sideRectHeight + pedestalLegsHeight
                });

                var x = mainRectX + border + position - (legsBottom / 2);
                if (config.sideLegs) {
                    x = mainRectX + border + mainRectWidth + 10;
                }

                var sideLegsOffset = 0;
                if (config.sideLegs) {
                    sideLegsOffset = 10;
                }

                drawVerticalDimension($canvas, {
                    topRight: {
                        x: mainRectX + border + sideLegsOffset + mainRectWidth + (dotedLineWidth * 3),
                        y: mainRectY - border - 1
                    },
                    bottomRight: {
                        x: mainRectX + border + sideLegsOffset + mainRectWidth + (dotedLineWidth * 3),
                        y: mainRectY + border + mainRectHeight + pedestalLegsHeight + 1
                    },
                    topLeft: {
                        x: mainRectX + mainRectWidth,
                        y: mainRectY - border - 1
                    },
                    bottomLeft: {
                        x: x + 1,
                        y: mainRectY + mainRectHeight + pedestalLegsHeight + border + 1
                    },
                    value: config.fullHeight
                });

                drawVerticalDimension($canvas, {
                    topRight: {
                        x: sideRectX + sideRectWidth + (dotedLineWidth * 3),
                        y: sideRectY - border - 1
                    },
                    bottomRight: {
                        x: sideRectX + sideRectWidth + (dotedLineWidth * 3),
                        y: sideRectY + border + sideRectHeight + pedestalLegsHeight + 1
                    },
                    topLeft: {
                        x: sideRectX + sideRectWidth,
                        y: sideRectY - border - 1
                    },
                    bottomLeft: {
                        x: sideRectX + sidePosition - (legsBottom / 2) + 1,
                        y: sideRectY + sideRectHeight + pedestalLegsHeight + border + 1
                    },
                    value: config.fullHeight
                });
            }

            //draw main image (from back-end)
            $canvas.drawImage({
                layer: true,
                draggable: true,
                groups: ['shapes'],
                dragGroups: ['shapes'],
                source: image,
                x: mainRectX - 0.5,
                y: mainRectY - 0.5,
                fromCenter: false
            });

            saveImage($canvas);
        };
        image.src = config.src;
    };

    if (typeof(bankConfig) !== 'undefined') {
        for(var i = 0; i < bankConfig.length; i++) {
            drawImage(bankConfig[i]);
        }
    }
});