$(document).ready(function onDocumentReady(e) {

    var $addPriceButton = $('.js-add-rrp');

    $addPriceButton.on('click', function onAddPriceButtonClick(e) {

        var count = $('.js-prices-area > .row.form-group').length;

        var html = ''
            + '<div class="row form-group price-row">'
            + '<div class="col-sm-3">'
            + '<input name="price[' + count + '][type]" type="hidden" value="' + $(this).data('type') + '">'
            + '<input class="form-control" min="0" step="1" name="price[' + count + '][min_qty]" type="number">'
            + '</div>'
            + '<div class="col-sm-3">'
            + '<div class="input-group">'
            + '<input class="form-control" min="0" step="0.01" name="price[' + count + '][rrp]" type="number">'
            + '<div class="input-group-addon"><span class="glyphicon glyphicon-gbp" aria-hidden="true"></span></div>'
            + '</div>'
            + '</div>'
            + '<div class="col-sm-1">'
            + '<button class="btn remove-rrp js-remove-rrp" type="button"><span class="glyphicon glyphicon-minus" aria-hidden="true"></span></button>'
            + '</div>'
            + '</div>';

        $(this).parents('.row').before($(html));
    });

    $(document).on('click', '.js-remove-rrp', function onRemovePriceButtonClick(e) {

        $(this).parents('.row.form-group').remove();
    });

    var $priceCheckbox = $('.js-paint-price');

    $priceCheckbox.on('change', function onPriceCheckboxChanged(e) {
        var $this = $(this);
        if ($this.is(':checked')) {
            $('.' + $this.data('target')).show();
        } else {
            $('.' + $this.data('target')).hide();
        }
    });

    $priceCheckbox.trigger('change');

    var $type = $('.js-type');

    $type.on('change', function onTypeChanged(e) {
        var value = parseInt($(this).find('option:selected').val());

        if (value === 1 || value === 2) {
            $('.js-plate').hide();
            $('.js-surround').show();
        } else {
            $('.js-plate').show();
            $('.js-surround').hide();
        }
    });

    $type.trigger('change');
});